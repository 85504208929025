<template>
  <div v-if="user">
    <v-card>
      <v-card-title>
      <v-icon color="primary">
        mdi-account
      </v-icon>
        <span class="pl-2">Informacioni i Llogarisë</span>
        <v-spacer></v-spacer>
        <v-btn depressed color="secondary" @click="$router.go(-1)" class="mx-1">
          Mbrapa
          <v-icon right dark> mdi-keyboard-return </v-icon>
        </v-btn>
        <v-btn
          depressed
          color="primary"
          @click="editUser"
          class="mx-1"
        >
          Redakto
          <v-icon right dark> mdi-pencil </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="user" class="mt-5">
        <!-- display user info -->
        <v-row>
          <v-col cols="12" md="3" v-if="user.avatar">
            <avatar-image-component :edit="false" :avatarUrl="user.avatar" :user="user.id"></avatar-image-component>
          </v-col>
          <v-row class="mx-2">
            <v-col cols="12" md="6">
              <p class = "font-weight-bold">Pseudonimi</p>
              <div class="mt-4">
                <p>{{ user.name }}</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <p class = "font-weight-bold">Email</p>
              <div class="mt-4">
                <p>{{ user.email }}</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <p class = "font-weight-bold">I regjistruar</p>
              <div class="mt-4">
                <p>{{ user.created_at | moment("LL") }} - {{ user.created_at | moment("LT") }}</p>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <p class = "font-weight-bold">Portofoli</p>
              <div class="mt-4">
                <p>{{ user.wallet }}</p>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <p class = "font-weight-bold">Roli</p>
            <div class="mt-4">
              <v-chip
                color="primary"
                dark
              >
                {{ getRoleValue(user.role) }}
                <v-icon class="ml-2">
                  {{ getIconOfRole(user.role) }}
                </v-icon>
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" md="9">
              <v-row>
                <v-col cols="12" md="6">
                  <p class = "font-weight-bold">Statusi</p>
                  <div class="mt-4">
                    <v-chip
                      :color="getStatusColor(user.status_id)"
                      dark
                    >
                      {{ getStatusValue(user.status_id) }}
                      <v-icon class="ml-2">
                        {{ getIconOfStatus(user.status_id) }}
                      </v-icon>
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <p class = "font-weight-bold">Mënyra e preferuar e pagesës</p>
                  <div class="mt-4">
                  <v-chip
                    :color="getRedemptionPreferenceColor(user.redemption_preference)"
                    dark
                  >
                    {{ getRedemptionPreferenceValue(user.redemption_preference) }}
                    <v-icon class="ml-2">
                      {{ getIconOfRedemptionPreference(user.redemption_preference) }}
                    </v-icon>
                  </v-chip>
                  </div>
                </v-col>

              </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    
    <!-- display driver documents -->
    <v-card v-if="user.role == 2 && user.driver_information" class="mt-5">
      <v-card-title>
        <v-icon color="primary">
          mdi-information
        </v-icon>
        <span class="pl-2">Informacion personal</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Emri</p>
            <div class="mt-4">
              <p>{{ user.driver_information.first_name }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Mbiemri</p>
            <div class="mt-4">
              <p>{{ user.driver_information.last_name }}</p>
            </div>
          </v-col>
          <!-- phone_number -->
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Numri telefonit</p>
            <div class="mt-4">
              <p>{{ user.driver_information.phone_number }}</p>
            </div>
          </v-col>
          <!-- address -->
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Adresa</p>
            <div class="mt-4">
              <p>{{ user.driver_information.address }}</p>
            </div>
          </v-col>
          <!-- email -->
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Email komunikimi</p>
            <div class="mt-4">
              <p>{{ user.driver_information.email }}</p>
            </div>
          </v-col>
          <!-- license -->
          <v-col cols="12" md="4">
            <p class = "font-weight-bold">Licenca</p>
            <div class="mt-4">
              <p>{{ user.driver_information.license_number }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Driver documents -->
    <v-card v-if="user.role == 2 && user.driver_information != null && user.driver_information.documents != null && user.driver_information.documents.length > 0" class="mt-5">
      <v-card-title>
        <v-icon color="primary">
          mdi-file-document
        </v-icon>
        <span class="pl-2">Dokumentet e shoferit</span>
      </v-card-title>
      <v-card-text v-for="(document, index) in user.driver_information.documents" :key="document.id">
        <v-row>
          <!-- Document Image -->
          <v-col cols="12" md="3">
            <div class="driver-document">
              <v-avatar rounded size="120" @click="viewDocumentImage(document)">
                <v-img :src="getDocumentImage(document)" alt="Document Image"></v-img>
              </v-avatar>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <p class = "font-weight-bold">Emri i dokumentit</p>
            <div class="mt-4">
              <p>{{ document.document_name }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <p class = "font-weight-bold">Numri i dokumentit</p>
            <div class="mt-4">
              <p>{{ document.document_number }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <p class = "font-weight-bold">Data e skadimit</p>
            <div class="mt-4">
              <p>{{ document.expiry_date | moment("LL") }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Approve or Reject Driver -->
    <v-card v-if="user.role == 2 && user.status_id == 4" class="mt-5">
      <v-card-title>
        <v-icon color="primary">
          mdi-account-check
        </v-icon>
        <span class="pl-2">Mirato ose refuzo shoferin</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between mb-6 bg-surface-variant">
            <v-btn
              depressed
              color="success"
              @click="approveDriver"
              class="mx-1"
            >
              Mirato
              <v-icon right dark> mdi-check </v-icon>
            </v-btn>

            <v-btn
              depressed
              color="error"
              @click="rejectDriver"
              class="mx-1"
            >
              Refuzo
              <v-icon right dark> mdi-close </v-icon>
            </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import AvatarImageComponent from '../../components/AvatarImageComponent.vue'
import {Keys} from '/src/config.js'

export default {
  components: {
    AvatarImageComponent,
    Keys
  },

  data() {
    return {
      user: null,
      user_id: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.params.user_id != null) {
      this.user_id = this.$route.params.user_id;
      this.fetchUser();
    }
  },
  methods: {
    fetchUser() {
      this.loading = true;
      axios
        .get(`/users/user/${this.user_id}`)
        .then((response) => {
          this.loading = false;
          this.user = response.data;
          console.log(this.user)
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë marrjes së të dhënave të përdoruesit",
            type: "error",
          });
          console.log(error);
          //this.$router.go(-1);
        });
    },
    editUser() {
      this.$router.push({
        name: "edit-user",
        params: { user_id: this.user.id },
      });
    },
    userStatus(status) {
      if (status == 1) {
        return "Aktiv";
      } else if (status == 2) {
        return "Në pritje";
      } else if (status == 3) {
        return "I pezulluar";
      } else if (status == 4) {
        return "Në shqyrtim";
      } else {
        return "E panjohur";
      }
    },
    getIconOfRedemptionPreference(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "mdi-bank";
      }
      else if(redemption_preference==3)
      {
        return "mdi-credit-card";
      }
      else if(redemption_preference==4)
      {
        return "mdi-credit-card-multiple";
      }
      else
      {
        return "mdi-cash";
      }
    },
    getRedemptionPreferenceColor(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "primary";
      }
      else if(redemption_preference==3)
      {
        return "info";
      }
      else if(redemption_preference==4)
      {
        return "secondary";
      }
      else
      {
        return "success";
      }
    },
    getRedemptionPreferenceValue(redemption_preference)
    {
      if(redemption_preference==2)
      {
        return "Bank";
      }
      else if(redemption_preference==3)
      {
        return "PayPal";
      }
      else if(redemption_preference==4)
      {
        return "Para Mobile";
      }
      else
      {
        return "Kesh";
      }
    },
    getStatusColor(status) {
      if (status == 1) {
        return "success";
      } else if (status == 2) {
        return "warning";
      } else if (status == 3) {
        return "error";
      } else if (status == 4) {
        return "info";
      }
    },
    getStatusValue(status) {
      if (status == 1) {
        return "Aktiv";
      } else if (status == 2) {
        return "Në pritje";
      } else if (status == 3) {
        return "I pezulluar";
      } else if (status == 4) {
        return "Në shqyrtim";
      } else {
        return "E panjohur";
      }
    },
    getIconOfStatus(status) {
      if (status == 1) {
        return "mdi-check-circle";
      } else if (status == 2) {
        return "mdi-alert-circle";
      } else if (status == 3) {
        return "mdi-close-circle";
      } else if (status == 4) {
        return "mdi-information-outline";
      } else {
        return "mdi-help-circle";
      }
    },
    getRoleValue(role) {
      if (role == 0) {
        return "Admin";
      } else if (role == 1) {
        return "Konsumator";
      } else if (role == 2) {
        return "Shofer";
      } else {
        return "E panjohur";
      }
    },
    getIconOfRole(role) {
      if (role == 0) {
        return "mdi-account-lock";
      } else if (role == 1) {
        return "mdi-account";
      } else if (role == 2) {
        return "mdi-account-tie-hat";
      } else {
        return "mdi-account-question";
      }
    },
    getDocumentImage(document)
    {
      return Keys.VUE_APP_API_URL + document.remote_file_path;
    },
    viewDocumentImage(document)
    {
      window.open(Keys.VUE_APP_API_URL + document.remote_file_path, '_blank');
    },
    rejectDriver() {
      this.$swal({
        input: 'textarea',
        inputPlaceholder: 'Pse po e refuzoni këtë shofer?',
        inputAttributes: {
          'aria-label': 'Pse po e refuzoni këtë shofer?'
        },
        title: "Refuzo shoferin",
        html: "Je i sigurt që dëshiron ta refuzosh këtë drejtues?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Po, refuzo!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.takeActionOnDriverServer(this.user.id, result.value, 2);
        }
      });
    },
    approveDriver() {
      this.$swal({
        title: "Aprovo shoferin",
        html: "Je i sigurt që dëshiron ta miratosh këtë drejtues?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Po, miratojeni!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.takeActionOnDriverServer(this.user.id, "Miratuar", 1);
        }
      });
    },
    takeActionOnDriverServer(user_id, reason, action)
    {
      this.loading = true;
      axios
        .post('/drivers/take-action', {
          driver_id: user_id,
          reason: reason,
          action: action
        })
        .then((response) => {
          this.loading = false;
          this.$notify({
            title: "Sukses",
            text: "Statusi i shoferit u përditësua me sukses",
            type: "success",
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            title: "Gabim",
            text: "Gabim në marrjen e veprimit",
            type: "error",
          });
          console.log(error);
        });
    }
  },

};
</script>

<style scoped>
.driver-document {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>